import React from "react"
import Img from "gatsby-image"
import "./styleGallery.scss"

const Gallery = ({ photos, handleOpen }) => {
  return (
    <div className="gallery">
      <ul>
        {photos.map((photo, i) => {
          return (
            <li
              key={i}
              style={{
                height: "100px",
                width:
                  (100 / photo.node.childImageSharp.fluid.presentationHeight) *
                    photo.node.childImageSharp.fluid.presentationWidth +
                  "px",
              }}
            >
              <button onClick={handleOpen(i)}>
                <Img
                  fluid={photo.node.childImageSharp.fluid}
                  style={{
                    maxHeight: "100%",
                    minWidth: "100%",
                    objectFit: "cover",
                    verticalAlign: "bottom",
                    height: "100%",
                  }}
                />
              </button>
            </li>
          )
        })}
        <li></li>
      </ul>
    </div>
  )
}

export default Gallery
