import React, { useState } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import Gallery from "../components/gallery";
import Layout from "../components/layout"
import SEO from "../components/seo"
import LightBox from '../components/lightbox';
import { Row, Col } from 'antd';
import Img from "gatsby-image"

const Elevage = () => {
    const intl = useIntl()

    const data = useStaticQuery(graphql`
        query {
            source: allFile(filter: { absolutePath: { regex: "/elevage/" }, childImageSharp: {fluid: {presentationHeight: {gte: 10}}} }) {
                edges {
                    node {
                        childImageSharp {
                            fluid(maxHeight: 500) {
                                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                presentationWidth
                                presentationHeight
                                aspectRatio
                            }
                        }
                    }
                }
            }
            markdownRemark(frontmatter: {slug: {eq: "elevage"}}) {
                html
                frontmatter {
                    slug
                    title
                    photos_presentation
                    photo_principale
                }
            }
            photo_principale: imageSharp(fluid: {originalName: {eq: "l_elevage.jpg"}}) {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
    `)

    const images = data.source.edges;
    const __html = data.markdownRemark.html

    const [showLightbox, setShowLightbox] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)

    const handleOpen = i => e => {
        setShowLightbox(true)
        setSelectedImage(i)
    }
    const handleClose = () => {
        setShowLightbox(false)
        setSelectedImage(null)
    }
    const handlePrevRequest = (i, length) => e => {
        setSelectedImage((i - 1 + length) % length)
    }
    const handleNextRequest = (i, length) => e => {
        setSelectedImage((i + 1) % length)
    }

    return (
        <Layout page='elevage' titles={[intl.formatMessage({ id: "BREADCRUMB.ELEVAGE" })]}>
            <SEO title={intl.formatMessage({ id: "ELEVAGE.TITLE" })} keywords={[`Elevage`, `Cedrenchante`, `chiens`, `chats`, `chiots`, `chatons`, `berger des shetland`, `chihuahua`, `epagneul tibetain`, `british shorthair`]}/>
            <Row type="flex" justify="center" align="top" gutter={24}>
                <Col xs={{span: 24, order: 2}} sm={{span: 24, order: 2}} md={{span: 10, order: 1}} lg={10} xl={10}>
                    <div>
                        <Img
                            fluid={data.photo_principale.fluid}
                            alt={"Elevage du Cèdre Enchanté"}
                            />
                    </div>
                    <Gallery photos={images} handleOpen={handleOpen}/>
                    {showLightbox && selectedImage !== null && (
                        <LightBox
                            images={images}
                            handleClose={handleClose}
                            handleNextRequest={handleNextRequest}
                            handlePrevRequest={handlePrevRequest}
                            selectedImage={selectedImage}
                        />
                    )}
                </Col>
                <Col xs={{span: 24, order: 1}} sm={{span: 24, order: 1}} md={{span: 14, order: 2}} lg={14} xl={14}>
                    <div style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{ __html }} />        
                </Col>
            </Row>
        </Layout>
    );
};

export default Elevage;
