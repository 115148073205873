import React from "react"
import Img from "gatsby-image"

// https://github.com/treyhuffine/lightbox-react/blob/master/src/lightbox-react.js
// https://reactjsexample.com/lightbox-for-components-or-images-built-for-react/
// import LightboxReact from "lightbox-react"
import Lightbox from "react-image-lightbox"
import 'react-image-lightbox/style.css';

const NonStretchedImage = props => {
    let normalizedProps = props
    if (props.fluid && props.fluid.presentationWidth) {
      normalizedProps = {
        ...props,
        style: {
          ...(props.style || {}),
          maxWidth: props.fluid.presentationWidth,
          // maxHeight: window.innerHeight - 50,
          height: window.innerHeight - 50,
          width: "auto",
          margin: "50px auto 0", // Used to center the image
        },
      }
    }
  
    return <Img {...normalizedProps} />
}

const GLightbox = ({
  images,
  selectedImage,
  handleClose,
  handlePrevRequest,
  handleNextRequest
}) => {
  const array = []

  images.forEach(image =>
    array.push(<NonStretchedImage fluid={image.node.childImageSharp.fluid} />)
  )

  const next = array[(selectedImage + 1) % array.length].props.fluid.srcSet
  const prev = array[(selectedImage + array.length - 1) % images.length].props.fluid.srcSet

  return (
    <Lightbox
      enableZoom={false}
      clickOutsideToClose={true}
      mainSrc={array[selectedImage].props.fluid.src}
      nextSrc={next}
      prevSrc={prev}
      onCloseRequest={handleClose}
      onMovePrevRequest={handlePrevRequest(selectedImage, array.length)}
      onMoveNextRequest={handleNextRequest(selectedImage, array.length)}
    />
  )
}

export default GLightbox

